.centered {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playerList {
  width: 20%;
}

.effectList {
  width: 20%;
}

.mainContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gameScreen {
  height: 90%;
}


body {
  background-color: #F5D6C6;
}